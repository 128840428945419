import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosTwo';
//
import { dispatch } from '../store';
import { NEW_HOST_API } from '../../config';

// ----------------------------------------------------------------------

const baseURL = NEW_HOST_API;
const url = `${baseURL}/api/business`;

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    // CREATE EVENT
    createClientSuccess(state, action) {
      const newClient = action.payload;
      state.isLoading = false;
      state.clients = [...state.clients, newClient];
    },

    // UPDATE EVENT
    updateClientSuccess(state, action) {
      const client = action.payload;
      const updateClient = state.clients.map((_client) => {
        if (_client.id === client.id) {
          return client;
        }
        return client;
      });

      state.isLoading = false;
      state.clients = updateClient;
    },

    // DELETE EVENT
    deleteClientSuccess(state, action) {
      const { clientId } = action.payload;
      const deleteClient = state.clients.filter((client) => client._id !== clientId);
      state.clients = deleteClient;
    },

    // SELECT EVENT
    selectClient(state, action) {
      const clientId = action.payload;
      state.isOpenModal = true;
      state.selectedClientId = clientId;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getClients() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getClientsSuccess(response.data.clients));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClient(filterData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const n = `${baseURL}/api/get-business`;
      const response = await axios.post(n, {company: {'$regex': filterData,$options:'i'}});
      dispatch(slice.actions.getClientSuccess(response.data.clients));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createClient(newClient) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(url, newClient);
      dispatch(slice.actions.createClientSuccess(response.data.client));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateClient(updateClient) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(url, updateClient);
      dispatch(slice.actions.updateClientSuccess(response.data.client));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteClient(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(url, { data: { clientId }});
      dispatch(slice.actions.deleteClientSuccess({ clientId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteClients(clientIds) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      clientIds.forEach(async element => {
        await axios.delete(url, { data: { clientId: element }});
        dispatch(slice.actions.deleteClientSuccess({ clientId: element }));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
