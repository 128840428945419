import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosTwo';
//
import { dispatch } from '../store';
import { NEW_HOST_API } from '../../config';

// ----------------------------------------------------------------------

const baseURL = NEW_HOST_API;
const url = `${baseURL}/api/invoice`;

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },
    payInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },
    sendInvoiceSuccess(state) {
      state.isLoading = false;
    },

    // CREATE EVENT
    createInvoiceSuccess(state, action) {
      const newInvoice = action.payload;
      state.isLoading = false;
      state.invoices = [...state.invoices, newInvoice];
    },

    // UPDATE EVENT
    updateInvoiceSuccess(state, action) {
      const invoice = action.payload;
      const updateInvoie = state.invoices.map((_invoice) => {
        if (_invoice.id === invoice.id) {
          return invoice;
        }
        return invoice;
      });

      state.isLoading = false;
      state.invoices = updateInvoie;
    },

    // DELETE EVENT
    deleteInvoiceSuccess(state, action) {
      const { invoiceId } = action.payload;
      const deleteInvoice = state.invoices.filter((invoice) => invoice._id !== invoiceId);
      state.invoices = deleteInvoice;
    },

    // SELECT EVENT
    selectInvoice(state, action) {
      const clientId = action.payload;
      state.isOpenModal = true;
      state.selectedClientId = clientId;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getInvoices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoice(filterData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const n = `${baseURL}/api/get-invoice`;
      const response = await axios.post(n, {_id: filterData});
      dispatch(slice.actions.getInvoiceSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createInvoice(newInvoice) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(url, newInvoice);
      dispatch(slice.actions.createInvoiceSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function payInvoice(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const n = `${baseURL}/api/pay`;
      const response = await axios.post(n, data);
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendInvoice(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const n = `${baseURL}/api/send-invoice`;
      const response = await axios.post(n, {invoiceId: id, company: '123'});
      dispatch(slice.actions.sendInvoiceSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateInvoice(updateInvoice) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(url, updateInvoice);
      dispatch(slice.actions.updateInvoiceSuccess(response.data.client));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInvoice(invoiceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(url, { data: { invoiceId }});
      dispatch(slice.actions.deleteInvoiceSuccess({ invoiceId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInvoices(invoiceIds) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      invoiceIds.forEach(async element => {
        await axios.delete(url, { data: { invoiceId: element }});
        dispatch(slice.actions.deleteInvoiceSuccess({ invoiceId: element }));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
